import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Snackbar,
} from '@mui/material';
import { Alert } from '@mui/material';
import { gql, useMutation } from '@apollo/client';

const CANCEL_BID = gql`
  mutation installerCancelBid($id: ID!) {
    installerCancelBid(id: $id)
  }
`;

const CancelBidDialog = (props) => {
  const { id, projectNumber, closeUpdateBidDialogCallback } = props;
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [cancelBid, { data }] = useMutation(CANCEL_BID, {
    refetchQueries: ['getProjects', 'installerPortalProjectInstallerBidsCount'],
  });

  React.useEffect(() => {
    if (data && data.createInstallerBid === 'OK') {
      setAlertOpen(true);
    }
  }, [data]);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleSubmit = () => {
    cancelBid({ variables: { id } });
    closeUpdateBidDialogCallback();
    setDialogOpen(false);
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  return (
    <React.Fragment>
      <Button color='error' aria-label='cancel bid' onClick={handleDialogOpen}>
        Cancel Bid
      </Button>
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Cancel Bid</DialogTitle>
        <DialogContent>
          <b>Are you sure you want to cancel your bid on P{projectNumber}</b>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color='darkGrey'>
            No
          </Button>
          <Button onClick={handleSubmit} color='error'>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <Alert
          onClose={handleAlertClose}
          severity='success'
          elevation={6}
          variant='filled'
        >
          Your bid on P{projectNumber} has been succesfully cancelled
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};

export default CancelBidDialog;
