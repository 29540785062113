import React from 'react';
import { gql, useApolloClient } from '@apollo/client';
import { TextField } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Autocomplete } from '@mui/material';
import { useHistory } from 'react-router-dom';

const SEARCH_PROJECTS = gql`
  query searchProjectsBids($term: String!) {
    installerPortalProjectInstallerBids(
      projectFilter: { projectNumber_like: $term }
      orderBy: projectNumber_DESC
      limit: 50
    ) {
      project {
        uuid
        projectNumber
        complete
      }
      customerApproved
      cancelled
      declined
    }
  }
`;

const useStyles = makeStyles()({
  // hide number spinners
  numberInput: {
    /* Chrome, Safari, Edge, Opera */
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    /* Firefox */
    '& input[type=number]': {
      MozAppearance: 'textfield',
    },
  },
});

const ProjectSearch = () => {
  const { classes } = useStyles();
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const apollo = useApolloClient();
  const history = useHistory();

  React.useEffect(() => {
    if (inputValue === '') {
      setOptions([]);
      return undefined;
    }
    apollo
      .query({
        query: SEARCH_PROJECTS,
        variables: { term: `${inputValue}%` },
      })
      .then(({ data, errors }) => {
        setOptions(
          !errors && data ? data.installerPortalProjectInstallerBids : []
        );
      });
  }, [inputValue, apollo]);

  const getBidStatus = (option) => {
    if (!option) return;
    if (option.customerApproved && !option.cancelled) return 'Approved';
    if (option.declined) return 'Declined';
    if (option.cancelled) return 'Cancelled';
    if (
      !option.customerApproved &&
      !option.declined &&
      !option.project.complete &&
      !option.cancelled
    )
      return 'Awaiting Approval';
    if (option.customerApproved && option.project.complete) return 'Completed';
  };

  return (
    <Autocomplete
      style={{ width: 300 }}
      getOptionLabel={(option) =>
        `P${option.project.projectNumber} - ${getBidStatus(option)}`
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      onChange={(event, newValue) => {
        if (newValue?.customerApproved && !newValue?.cancelled) {
          history.push(`/projects/${newValue.project.uuid}`);
        }
        return null;
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label='Search'
          variant='outlined'
          type='text'
          className={classes.numberInput}
          fullWidth
        />
      )}
    />
  );
};

export default ProjectSearch;
