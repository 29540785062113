import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { Grid, CircularProgress, Typography, Link } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Pagination } from '@mui/material';
import BiddableProjectSummary from '../components/BiddableProjectSummary';

const displayedProjectCount = 8;

export const PROJECT_COUNT = gql`
  query getBiddableProjectsCount {
    installerBiddableProjectsCount
    installerReviewsSummary {
      belowThreshold
    }
  }
`;

export const GET_PROJECTS = gql`
  query getBiddableProjects($skip: Int!) {
    installerBiddableProjects(orderBy: projectNumber_DESC, limit: ${displayedProjectCount}, skip: $skip) {
      uuid
      projectNumber
      euAddressShort
      installationCost
      onSiteDate
      plantOffHireDate
      floorArea
      files {
        id
        url
        fileName
        createdAt
        type {
          description
        }
      }
    }
  }
`;

const useStyles = makeStyles()(() => ({
  root: {
    flexGrow: 1,
  },
  center: {
    position: 'fixed',
    bottom: '0%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

const Projects = (props) => {
  const { skip } = props;
  const { loading, error, data } = useQuery(GET_PROJECTS, {
    fetchPolicy: 'network-only',
    variables: { skip },
  });

  if (loading) return <CircularProgress color='inherit' />;
  if (error) return <p>Error :(</p>;

  return (
    <React.Fragment>
      {data.installerBiddableProjects.map((project) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={project.uuid}>
          <BiddableProjectSummary
            id={project.uuid}
            projectNumber={project.projectNumber}
            euAddressShort={project.euAddressShort}
            complete={project.complete}
            biddable={true}
            installationCost={project.installationCost}
            onSiteDate={project.onSiteDate}
            offSiteDate={project.plantOffHireDate}
            floorArea={project.floorArea}
            files={project.files}
          />
        </Grid>
      ))}
    </React.Fragment>
  );
};

const BiddableProjectList = () => {
  const { classes } = useStyles();
  const [skip, setSkip] = React.useState(0);
  const { loading, error, data } = useQuery(PROJECT_COUNT);

  if (loading) return <CircularProgress color='inherit' />;
  if (error) return <p>Project List Error</p>;
  if (data.installerBiddableProjectsCount < 1)
    return (
      <Typography variant='h4'>
        Nothing to see here! Check back later when more projects become
        available.
      </Typography>
    );

  if (data.installerReviewsSummary.belowThreshold)
    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography variant='h6'>
            We are sorry, but we are unable to process your bids at this time.
          </Typography>
          <Typography variant='h6'>
            Your rating has fallen below the required threshold, which is
            necessary to participate in our bidding system.
          </Typography>
          <Typography variant='h6'>
            To resolve this issue, please contact our contracts team at{' '}
            <Link href='mailto:contracts@hi-level.co.uk'>
              contracts@hi-level.co.uk
            </Link>
            .
          </Typography>
          <Typography variant='h6'>
            They will be happy to assist you in understanding your rating and
            how to improve it.
          </Typography>
        </Grid>
      </Grid>
    );

  const pages = Math.ceil(
    data.installerBiddableProjectsCount / displayedProjectCount
  );

  const handlePageChange = (_, page) => {
    setSkip((page - 1) * displayedProjectCount);
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Projects skip={skip} />
        <Grid item xs={12} className={classes.center}>
          <Pagination count={pages} onChange={handlePageChange} />
        </Grid>
      </Grid>
    </div>
  );
};

export default BiddableProjectList;
