import React from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { Email as EmailIcon, Phone as PhoneIcon } from '@mui/icons-material';
import CustomerChatDialog from './CustomerChatDialog';

function ListItemLink(props) {
  return <ListItem button component='a' {...props} />;
}

const CustomerDetails = (props) => {
  const { name, email, phone, projectId } = props;

  return (
    <Card style={{ minHeight: '250px' }}>
      <CardHeader
        title={name}
        subheader='Customer'
        action={<CustomerChatDialog projectId={projectId} />}
      />
      <CardContent>
        <List component='nav' aria-label='customer contact details'>
          {email && (
            <ListItemLink href={`mailto:${email}`}>
              <ListItemIcon>
                <EmailIcon />
              </ListItemIcon>
              <ListItemText primary={email} />
            </ListItemLink>
          )}
          {phone && (
            <ListItemLink href={`tel:${phone}`}>
              <ListItemIcon>
                <PhoneIcon />
              </ListItemIcon>
              <ListItemText primary={phone} />
            </ListItemLink>
          )}
        </List>
      </CardContent>
      <CardActions disableSpacing></CardActions>
    </Card>
  );
};

export default CustomerDetails;
