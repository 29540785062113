import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Snackbar,
  Input,
  Typography,
  InputAdornment,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Alert } from '@mui/material';
import { gql, useMutation } from '@apollo/client';

const PLACE_BID = gql`
  mutation createInstallerBid($projectId: String!, $bid: Float!) {
    createInstallerBid(projectId: $projectId, bid: $bid)
  }
`;

const useStyles = makeStyles()((theme) => ({
  fab: {
    position: 'relative',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  open: {
    marginLeft: 'auto',
  },
  warning: {
    color: 'red',
  },
}));

const BidDialog = (props) => {
  const { classes } = useStyles();
  const { projectId, installationCost, projectNumber } = props;
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [bid, setBid] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [placeBid, { data }] = useMutation(PLACE_BID, {
    refetchQueries: [
      'getProjects',
      'installerPortalProjectInstallerBidsCount',
      'getBiddableProjects',
      'getBiddableProjectsCount',
    ],
  });

  React.useEffect(() => {
    if (data && data.createInstallerBid === 'OK') {
      setAlertOpen(true);
    }
  }, [data]);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleSubmit = () => {
    placeBid({ variables: { projectId, bid: Number(bid) } });
    setDialogOpen(false);
  };

  const handleBidChange = (event) => {
    if (event.target.value > installationCost) {
      setErrorMessage('The bid amount is too high');
    } else {
      setErrorMessage('');
    }

    setBid(event.target.value);
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  return (
    <React.Fragment>
      <Button
        variant='contained'
        color='grey'
        aria-label='place a bid'
        className={classes.open}
        onClick={handleDialogOpen}
      >
        Place a bid
      </Button>
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Place a Bid</DialogTitle>
        <DialogContent>
          <Typography variant='subtitle1'>
            By submiting this bid I understand that I am required to:
          </Typography>
          <ul>
            <li>Create and send out my own RAMs</li>
            <li>
              Provide installation team with all up to date certifications
              relevant to completion of the build
            </li>
            <li>Adequate insurance to cover the project and scope of works</li>
            <li>Provide all necessary plant and equipment</li>
            <li>Fulfill the contract in full</li>
          </ul>
          <Input
            autoFocus
            type='number'
            className={classes.input}
            required
            fullWidth
            value={bid}
            onChange={handleBidChange}
            startAdornment={<InputAdornment position='start'>£</InputAdornment>}
          />
          {errorMessage && (
            <div className={classes.warning}>{errorMessage}</div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color='primary'>
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            color='primary'
            disabled={bid <= 0 || bid > installationCost}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <Alert
          onClose={handleAlertClose}
          severity='success'
          elevation={6}
          variant='filled'
        >
          Bid of £{bid} has been placed on project P{projectNumber}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};

export default BidDialog;
