import React from 'react';
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Fade,
  IconButton,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import {
  ArrowBack as ArrowBackIcon,
  ArrowForward as ArrowForwardIcon,
  Close as CloseIcon,
  CloudDownload as CloudDownloadIcon,
} from '@mui/icons-material';
import { Document, Page } from 'react-pdf';
import axios from 'axios';
import { saveAs } from 'file-saver';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

const useStyles = makeStyles()((theme) => ({
  pageControls: {
    position: 'absolute',
    bottom: '7%',
    left: '40%',
    background: 'white',
  },
  pageCount: {
    color: 'black !important',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  downloadButton: {
    color: 'white',
  },
}));

const DocumentViewer = (props) => {
  const { title, file, fileName, open, onClose } = props;
  const { classes } = useStyles();
  const [numPages, setNumPages] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [pageControlsVisible, setPageControlsVisible] = React.useState(false);

  React.useEffect(() => {
    setPageNumber(1);
  }, [file]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleBackClick = () => {
    setPageNumber(pageNumber - 1);
  };

  const handleFwdClick = () => {
    setPageNumber(pageNumber + 1);
  };

  const handleMouseEnter = () => {
    setPageControlsVisible(true);
  };

  const handleMouseLeave = () => {
    setPageControlsVisible(false);
  };

  const handleDownloadClick = () => {
    axios
      .get(file, { responseType: 'blob' })
      .then(({ data }) => saveAs(data, fileName));
  };

  return (
    <Dialog open={open} maxWidth='lg'>
      {title && (
        <DialogTitle>
          {title}
          {onClose && (
            <IconButton
              aria-label='close'
              className={classes.closeButton}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          )}
        </DialogTitle>
      )}
      <DialogContent
        dividers
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Document
          className={classes.document}
          file={file}
          onLoadSuccess={onDocumentLoadSuccess}
          externalLinkTarget='_blank'
        >
          <Page pageNumber={pageNumber} />
        </Document>
        {numPages && (
          <Fade in={pageControlsVisible}>
            <ButtonGroup
              size='small'
              aria-label='page controls'
              className={classes.pageControls}
            >
              <Button disabled={pageNumber === 1} onClick={handleBackClick}>
                <ArrowBackIcon />
              </Button>
              <Button disabled className={classes.pageCount}>
                {pageNumber} of {numPages}
              </Button>
              <Button
                disabled={pageNumber >= numPages}
                onClick={handleFwdClick}
              >
                <ArrowForwardIcon />
              </Button>
            </ButtonGroup>
          </Fade>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          color='primaryRed'
          className={classes.downloadButton}
          disabled={!numPages}
          startIcon={<CloudDownloadIcon />}
          onClick={handleDownloadClick}
        >
          Download
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DocumentViewer;
