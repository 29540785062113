import React from 'react';
import clsx from 'clsx';
import { Button, AppBar, Toolbar, IconButton, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Menu as MenuIcon } from '@mui/icons-material';
import { useRecoilState } from 'recoil';
import { useAuth0 } from '../react-auth0-spa';
import { drawerOpenState } from '../atoms';
import NavProfile from './NavProfile';

const drawerWidth = 240;

const useStyles = makeStyles()((theme) => ({
  title: {
    flexGrow: 1,
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#db1e36',
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
}));

const NavBar = () => {
  const { classes } = useStyles();
  const { isAuthenticated, loginWithRedirect, user } = useAuth0();
  const [drawerOpen, setDrawerOpen] = useRecoilState(drawerOpenState);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  return (
    <main>
      <AppBar
        position='fixed'
        className={clsx(classes.appBar, {
          [classes.appBarShift]: drawerOpen,
        })}
      >
        <Toolbar>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            onClick={handleDrawerOpen}
            edge='start'
            className={clsx(classes.menuButton, drawerOpen && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant='h6' className={classes.title}>
            Hi-Level Installer Portal
          </Typography>
          {!isAuthenticated && (
            <Button color='inherit' onClick={() => loginWithRedirect({})}>
              Log in
            </Button>
          )}
          {isAuthenticated && <NavProfile user={user} />}
        </Toolbar>
      </AppBar>
    </main>
  );
};

export default NavBar;
