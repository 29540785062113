import React from 'react';
import { useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import {
  Grid,
  CircularProgress,
  Typography,
  Card,
  CardHeader,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import ProjectManagerDetails from '../components/ProjectManagerDetails';
import Documents from '../components/Documents';
import ProjectDetails from '../components/ProjectDetails';
import CustomerDetails from '../components/CustomerDetails';
import CancelInstallationDialog from '../components/CancelInstallationDialog';

const GET_PROJECT = gql`
  query getProject($id: ID!) {
    installerProject(id: $id) {
      uuid
      projectNumber
      euName
      euAddressShort
      euAddressLong
      onSiteDate
      onSiteTime
      plantOffHireDate
      offSiteTime
      user {
        name
        email
      }
      files {
        id
        url
        fileName
        createdAt
        type {
          description
        }
      }
      customerContact {
        phone
        email
      }
    }
  }
`;

const useStyles = makeStyles()((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

const Project = () => {
  const { classes } = useStyles();
  const { id } = useParams();
  const { loading, error, data } = useQuery(GET_PROJECT, {
    variables: { id },
  });

  if (loading) return <CircularProgress color='inherit' />;
  if (error) return <p>Project Error</p>;
  if (!data.installerProject)
    return <Typography variant='h4'>Oh no! Project not found.</Typography>;

  const {
    uuid,
    projectNumber,
    euAddressShort,
    onSiteDate,
    onSiteTime,
    plantOffHireDate,
    offSiteTime,
    user,
    euAddressLong,
    euName,
    files,
    customerContact,
  } = data.installerProject;

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              title={`P${projectNumber} ${euAddressShort}`}
              action={
                <CancelInstallationDialog
                  projectId={id}
                  projectNumber={projectNumber}
                />
              }
            />
          </Card>
        </Grid>
        <Grid item xs={12}>
          <ProjectDetails
            onSiteDate={onSiteDate}
            onSiteTime={onSiteTime}
            offSiteDate={plantOffHireDate}
            offSiteTime={offSiteTime}
            euAddressLong={euAddressLong}
            euAddressShort={euAddressShort}
          />
        </Grid>
        <Grid item xs={12}></Grid>
        {user && (
          <Grid item xs={12} sm={6}>
            <ProjectManagerDetails
              name={user.name}
              email={user.email}
              projectId={uuid}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <CustomerDetails
            name={euName}
            phone={customerContact.phone}
            email={customerContact.email}
            projectId={uuid}
          />
        </Grid>
        <Grid item xs={12}>
          <Documents files={files} />
        </Grid>
      </Grid>
    </div>
  );
};

export default Project;
