import React from 'react';
import {
  Card,
  CardContent,
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from '@mui/material';
import DateFormat from './DateFormat';
import TimeFormat from './TimeFormat';

const ProjectDetails = (props) => {
  const {
    onSiteDate,
    onSiteTime,
    offSiteDate,
    offSiteTime,
    euAddressLong,
  } = props;

  return (
    <Card>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TableContainer component={Paper}>
              <Table aria-label='project details'>
                <TableBody>
                  <TableRow>
                    <TableCell>Site Address</TableCell>
                    <TableCell style={{ whiteSpace: 'pre-line' }}>
                      {euAddressLong}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TableContainer component={Paper}>
              <Table aria-label='project details'>
                <TableBody>
                  {onSiteDate && (
                    <TableRow>
                      <TableCell>On Site Date</TableCell>
                      <TableCell>
                        <DateFormat date={onSiteDate} />{' '}
                        <TimeFormat time={onSiteTime} />
                      </TableCell>
                    </TableRow>
                  )}
                  {offSiteDate && (
                    <TableRow>
                      <TableCell>Off Site Date</TableCell>
                      <TableCell>
                        <DateFormat date={offSiteDate} />{' '}
                        <TimeFormat time={offSiteTime} />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ProjectDetails;
