import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Snackbar,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Alert } from '@mui/material';
import { gql, useMutation } from '@apollo/client';
import { Link } from 'react-router-dom';

const CANCEL_PROJECT = gql`
  mutation cancelInstallerProject($id: ID!) {
    cancelInstallerProject(id: $id)
  }
`;

const useStyles = makeStyles()((theme) => ({
  open: {
    marginLeft: 'auto',
  },
}));

const CancelInstallationDialog = (props) => {
  const { classes } = useStyles();
  const { projectId, projectNumber } = props;
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [cancelProject, { data }] = useMutation(CANCEL_PROJECT, {
    refetchQueries: ['getProjects'],
  });

  React.useEffect(() => {
    if (data && data.cancelInstallerProject === 'OK') {
      setAlertOpen(true);
    }
  }, [data]);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleConfirm = () => {
    cancelProject({ variables: { id: projectId } });
    setDialogOpen(false);
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  return (
    <React.Fragment>
      <Button
        variant='contained'
        color='grey'
        aria-label='cancel installation'
        className={classes.open}
        onClick={handleDialogOpen}
      >
        Cancel Installation
      </Button>
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Warning!</DialogTitle>
        <DialogContent>
          <p>
            <b>This cannot be undone</b>, are you sure you want to cancel
            installation of P{projectNumber}?
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color='darkGrey'>
            Close
          </Button>
          <Button
            onClick={handleConfirm}
            color='error'
            component={Link}
            to={'/projects/'}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <Alert
          onClose={handleAlertClose}
          severity='success'
          elevation={6}
          variant='filled'
        >
          Successfully cancelled project P{projectNumber}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};

export default CancelInstallationDialog;
