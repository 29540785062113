import React from 'react';
import { IconButton, Menu, MenuItem, Snackbar } from '@mui/material';
import { AccountCircle } from '@mui/icons-material';
import { useAuth0 } from '../react-auth0-spa';
import axios from 'axios';
import { Alert } from '@mui/material';
import { useHistory } from 'react-router-dom';

const NavProfile = ({ user }) => {
  const { logout } = useAuth0();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const profileOpen = Boolean(anchorEl);
  const [alertOpen, setAlertOpen] = React.useState(false);
  const history = useHistory();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileClose = () => {
    setAnchorEl(null);
  };

  const changePassword = React.useCallback(async () => {
    // close profile menu
    handleProfileClose();
    // send password reset request
    const { status } = await axios.post(
      'https://auth.iqd-pro.com/dbconnections/change_password',
      {
        client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
        email: user.email,
        connection: 'Username-Password-Authentication',
      }
    );
    // check status & display success message
    if (status === 200) {
      setAlertOpen(true);
    }
  }, [user]);

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const openProfile = () => {
    history.push('/profile');
  };

  return (
    <div>
      <IconButton
        aria-label='account of current user'
        aria-controls='menu-appbar'
        aria-haspopup='true'
        onClick={handleMenu}
        color='inherit'
      >
        <AccountCircle />
      </IconButton>
      <Menu
        id='menu-appbar'
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={profileOpen}
        onClose={handleProfileClose}
      >
        <MenuItem onClick={openProfile}>Your Profile</MenuItem>
        <MenuItem onClick={changePassword}>Change Password</MenuItem>
        <MenuItem onClick={() => logout()}>Log out</MenuItem>
      </Menu>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleAlertClose}
      >
        <Alert onClose={handleAlertClose} elevation={6} variant='filled'>
          We've just sent you an email to reset your password.
        </Alert>
      </Snackbar>
    </div>
  );
};

export default NavProfile;
