import React from 'react';
import { Router, Switch, Redirect } from 'react-router-dom';
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  split,
} from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { setContext } from '@apollo/client/link/context';
import { CssBaseline, Backdrop, CircularProgress } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import packageInfo from '../package.json';
import NavBar from './components/NavBar';
import NavDrawer from './components/NavDrawer';
import { useAuth0 } from './react-auth0-spa';
import ProjectList from './containers/ProjectList';
import Project from './containers/Project';
import history from './utils/history';
import PrivateRoute from './components/PrivateRoute';
import BiddableProjectList from './containers/BiddableProjectList';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import UserProfile from './components/UserProfile';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor) => augmentColor({ color: { main: mainColor } });
const theme = createTheme({
  palette: {
    grey: createColor('#e0e0e0'),
    darkGrey: createColor('#4d4d4d'),
    primaryRed: createColor('#db1e36'),
  },
});

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const App = () => {
  const { loading, getTokenSilently } = useAuth0();
  const { classes } = useStyles();

  let httpLink = createHttpLink({
    uri: process.env.REACT_APP_API_URL,
  });

  let wsLink = new GraphQLWsLink(
    createClient({
      url: process.env.REACT_APP_API_WS_URL,
      connectionParams: async () => {
        const token = await getTokenSilently();
        return {
          authentication: token ? `${token}` : '',
        };
      },
    })
  );

  const authLink = setContext(async (_, { headers }) => {
    const token = await getTokenSilently();
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  httpLink = authLink.concat(httpLink);

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    httpLink
  );

  const client = new ApolloClient({
    link: authLink.concat(splitLink),
    cache: new InMemoryCache(),
    name: 'Installer Portal',
    version: packageInfo.version,
  });

  if (loading) {
    return (
      <Backdrop className={classes.backdrop} open>
        <CircularProgress color='inherit' />
      </Backdrop>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <CssBaseline />
        <Router history={history}>
          <ApolloProvider client={client}>
            <header>
              <NavBar />
              <NavDrawer />
            </header>
            <div className={classes.content}>
              <div className={classes.drawerHeader} />
              <Switch>
                <PrivateRoute path='/projects/:id' component={Project} />
                <PrivateRoute path='/projects' component={ProjectList} />
                <PrivateRoute
                  path='/biddable-projects'
                  component={BiddableProjectList}
                />
                <PrivateRoute path='/profile' component={UserProfile} />
                <Redirect to='/projects' />
              </Switch>
            </div>
          </ApolloProvider>
        </Router>
      </div>
    </ThemeProvider>
  );
};

export default App;
