import React from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
import BidDialog from './BidDialog';
import DateFormat from './DateFormat';
import DrawingsDialog from './DrawingsDialog';

const BiddableProjectSummary = (props) => {
  const {
    id,
    projectNumber,
    euAddressShort,
    installationCost,
    onSiteDate,
    offSiteDate,
    floorArea,
    files,
  } = props;

  return (
    <Card>
      <CardHeader title={`P${projectNumber}`} subheader={euAddressShort} />
      <CardContent>
        <TableContainer>
          <Table aria-label='project details'>
            <TableBody>
              <TableRow>
                <TableCell>On Site Date</TableCell>
                <TableCell>
                  <DateFormat date={onSiteDate} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Off Site Date</TableCell>
                <TableCell>
                  <DateFormat date={offSiteDate} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Floor Area</TableCell>
                <TableCell>{floorArea}m²</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
      {id && (
        <CardActions>
          <DrawingsDialog files={files} />
          <BidDialog
            projectId={id}
            installationCost={installationCost}
            projectNumber={projectNumber}
          />
        </CardActions>
      )}
    </Card>
  );
};

export default BiddableProjectSummary;
