import React from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import {
  Grid,
  CircularProgress,
  Card,
  TextField,
  CardContent,
  Button,
  Snackbar,
  Alert,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Reviews from './Reviews';
import ReviewsSummary from './ReviewsSummary';

const GET_PROFILE = gql`
  query getInstallerProfile {
    installerProfile {
      name
      phoneNumber
      contact
      address
      postCode
      email
      installerReviews {
        installationQuality
        serviceQuality
        communicationQuality
        siteEtiquette
        healthAndSafety
        title
        notes
      }
    }
    installerReviewsSummary {
      installationQuality
      serviceQuality
      communicationQuality
      siteEtiquette
      healthAndSafety
      average
    }
  }
`;

const UPDATE_PROFILE = gql`
  mutation updateInstallerProfile(
    $installerProfileInput: InstallerProfileInput
  ) {
    updateInstallerProfile(installerProfileInput: $installerProfileInput)
  }
`;

const useStyles = makeStyles()(() => ({
  root: {
    flexGrow: 1,
  },
  card: {
    maxWidth: 600,
    margin: '0 auto',
  },
  grid: {
    display: 'flex',
    justifyContent: 'center',
  },
  reviews: {
    display: 'flex',
  },
}));

const UserProfile = () => {
  const { classes } = useStyles();
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [name, setName] = React.useState('');
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [contact, setContact] = React.useState('');
  const [address, setAddress] = React.useState('');
  const [postCode, setPostCode] = React.useState('');
  const [reviews, setReviews] = React.useState([]);
  const [reviewsSummary, setReviewsSummary] = React.useState('');
  const [updateProfile, { data: updateData }] = useMutation(UPDATE_PROFILE);

  React.useEffect(() => {
    if (updateData && updateData.updateInstallerProfile === 'OK') {
      setAlertOpen(true);
    }
  }, [updateData]);

  const { loading, error, data } = useQuery(GET_PROFILE, {
    onCompleted: (data) => {
      setName(data.installerProfile.name);
      setPhoneNumber(data.installerProfile.phoneNumber);
      setEmail(data.installerProfile.email);
      setContact(data.installerProfile.contact);
      setAddress(data.installerProfile.address);
      setPostCode(data.installerProfile.postCode);
      setReviews(data.installerProfile.installerReviews);
      setReviewsSummary(data.installerReviewsSummary);
    },
  });

  if (loading) return <CircularProgress color='inherit' />;
  if (error || !data.installerProfile) return <p>Profile Error</p>;

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleContactChange = (event) => {
    setContact(event.target.value);
  };

  const handleAddressChange = (event) => {
    setAddress(event.target.value);
  };

  const handlePostCodeChange = (event) => {
    setPostCode(event.target.value);
  };

  const handleSubmit = (event) => {
    // prevent page refresh
    event.preventDefault();

    updateProfile({
      variables: {
        installerProfileInput: {
          name,
          contact,
          phoneNumber,
          email,
          address,
          postCode,
        },
      },
    });
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={1} className={classes.grid}>
        <Grid item xs={12} md={4}>
          <Card className={classes.card}>
            <CardContent>
              <Card className={classes.card}>
                <CardContent>
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <TextField
                          type={'text'}
                          value={name}
                          placeholder='Company Name'
                          label='Company Name'
                          variant='outlined'
                          fullWidth
                          required
                          onChange={handleNameChange}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          type={'text'}
                          value={contact}
                          placeholder='Primary Contact Name'
                          label='Primary Contact Name'
                          variant='outlined'
                          fullWidth
                          required
                          onChange={handleContactChange}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          type={'tel'}
                          value={phoneNumber}
                          placeholder='Phone Number'
                          label='Phone Number'
                          variant='outlined'
                          fullWidth
                          required
                          onChange={handlePhoneNumberChange}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          type={'email'}
                          value={email}
                          placeholder='Email'
                          label='Email'
                          variant='outlined'
                          fullWidth
                          required
                          onChange={handleEmailChange}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          type={'text'}
                          value={address}
                          placeholder='Address'
                          label='Address'
                          variant='outlined'
                          multiline
                          minRows={5}
                          fullWidth
                          required
                          onChange={handleAddressChange}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          type={'text'}
                          value={postCode}
                          placeholder='Post Code'
                          label='Post Code'
                          variant='outlined'
                          fullWidth
                          required
                          onChange={handlePostCodeChange}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          type='submit'
                          variant='contained'
                          color='grey'
                          fullWidth
                        >
                          Save
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                  <Snackbar
                    open={alertOpen}
                    autoHideDuration={6000}
                    onClose={handleAlertClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                  >
                    <Alert
                      onClose={handleAlertClose}
                      severity='success'
                      elevation={6}
                      variant='filled'
                    >
                      Saved!
                    </Alert>
                  </Snackbar>
                </CardContent>
              </Card>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4} className={classes.reviews}>
          <Card className={classes.card}>
            <CardContent>
              <ReviewsSummary summary={reviewsSummary} />
            </CardContent>
          </Card>
        </Grid>
        {reviews.length > 0 && (
          <Grid item xs={12} className={classes.reviews}>
            <Card className={classes.card}>
              <CardContent>
                <Reviews reviews={reviews} />
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default UserProfile;
