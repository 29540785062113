import React from 'react';
import { Card, CardContent, Grid, Typography, Rating } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  ratingLabel: {
    width: 150,
  },
  grid: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const ReviewsSummary = (props) => {
  const { classes } = useStyles();
  const { summary } = props;

  return (
    <React.Fragment>
      <Grid container spacing={2} className={classes.grid}>
        <Grid item>
          <Card>
            <CardContent>
              <Grid container alignItems='center' spacing={2}>
                <Grid item xs={12}>
                  <Grid
                    container
                    alignItems='center'
                    justifyContent='space-between'
                  >
                    <Grid item className={classes.ratingLabel}>
                      <Typography>Installation</Typography>
                    </Grid>
                    <Grid item>
                      <Rating
                        precision={0.1}
                        value={summary.installationQuality}
                        readOnly
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid
                    container
                    alignItems='center'
                    justifyContent='space-between'
                  >
                    <Grid item className={classes.ratingLabel}>
                      <Typography>Service</Typography>
                    </Grid>
                    <Grid item>
                      <Rating
                        precision={0.1}
                        value={summary.serviceQuality}
                        readOnly
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid
                    container
                    alignItems='center'
                    justifyContent='space-between'
                  >
                    <Grid item className={classes.ratingLabel}>
                      <Typography>Communication</Typography>
                    </Grid>
                    <Grid item>
                      <Rating
                        precision={0.1}
                        value={summary.communicationQuality}
                        readOnly
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid
                    container
                    alignItems='center'
                    justifyContent='space-between'
                  >
                    <Grid item className={classes.ratingLabel}>
                      <Typography>Site Etiquette</Typography>
                    </Grid>
                    <Grid item>
                      <Rating
                        precision={0.1}
                        value={summary.siteEtiquette}
                        readOnly
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid
                    container
                    alignItems='center'
                    justifyContent='space-between'
                  >
                    <Grid item className={classes.ratingLabel}>
                      <Typography>Health and Safety</Typography>
                    </Grid>
                    <Grid item>
                      <Rating
                        precision={0.1}
                        value={summary.healthAndSafety}
                        readOnly
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent='space-between'>
                    <Grid item className={classes.ratingLabel}>
                      <Typography>Average</Typography>
                    </Grid>
                    <Grid item>
                      <Rating
                        precision={0.1}
                        value={summary.average}
                        readOnly
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default ReviewsSummary;
