import React from 'react';
import { useState } from 'react';
import {
  Card,
  CardContent,
  Grid,
  IconButton,
  Typography,
  Rating,
} from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  ratingLabel: {
    width: 150,
  },
  grid: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  notes: {
    maxHeight: 100,
    overflowY: 'auto',
  },
}));

const Reviews = (props) => {
  const { classes } = useStyles();
  const { reviews } = props;
  const [currentReviewIndex, setCurrentReviewIndex] = useState(0);

  const goToNextReview = () => {
    setCurrentReviewIndex((currentReviewIndex + 1) % reviews.length);
  };

  const goToPreviousReview = () => {
    setCurrentReviewIndex(
      (currentReviewIndex - 1 + reviews.length) % reviews.length
    );
  };

  return (
    <React.Fragment>
      <Grid container spacing={2} className={classes.grid}>
        <Grid item xs={1}>
          <IconButton onClick={goToPreviousReview}>
            <ArrowBack />
          </IconButton>
        </Grid>
        <Grid item xs={10}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant='h6'>
                    {reviews[currentReviewIndex].title}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent='space-between'>
                    <Grid item className={classes.ratingLabel}>
                      <Typography>Installation</Typography>
                    </Grid>
                    <Grid item>
                      <Rating
                        value={reviews[currentReviewIndex].installationQuality}
                        readOnly
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent='space-between'>
                    <Grid item className={classes.ratingLabel}>
                      <Typography>Service</Typography>
                    </Grid>
                    <Grid item>
                      <Rating
                        value={reviews[currentReviewIndex].serviceQuality}
                        readOnly
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent='space-between'>
                    <Grid item className={classes.ratingLabel}>
                      <Typography>Communication</Typography>
                    </Grid>
                    <Grid item>
                      <Rating
                        value={reviews[currentReviewIndex].communicationQuality}
                        readOnly
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent='space-between'>
                    <Grid item className={classes.ratingLabel}>
                      <Typography>Site Etiquette</Typography>
                    </Grid>
                    <Grid item>
                      <Rating
                        value={reviews[currentReviewIndex].siteEtiquette}
                        readOnly
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent='space-between'>
                    <Grid item className={classes.ratingLabel}>
                      <Typography>Health and Safety</Typography>
                    </Grid>
                    <Grid item>
                      <Rating
                        value={reviews[currentReviewIndex].healthAndSafety}
                        readOnly
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Typography className={classes.notes}>
                {reviews[currentReviewIndex].notes}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={1}>
          <IconButton onClick={goToNextReview}>
            <ArrowForward />
          </IconButton>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Reviews;
