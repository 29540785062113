import React from 'react';
import { Dialog, DialogContent, DialogActions, Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Documents from './Documents';

const useStyles = makeStyles()((theme) => ({
  open: {
    marginLeft: 'auto',
  },
}));

const DrawingsDialog = (props) => {
  const { classes } = useStyles();
  const { files } = props;
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  return (
    <React.Fragment>
      <Button
        variant='contained'
        color='grey'
        aria-label='view documents'
        className={classes.open}
        onClick={handleDialogOpen}
      >
        View Drawings
      </Button>
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        maxWidth={'lg'}
        fullWidth={true}
      >
        <DialogContent>
          <Documents files={files} drawingsOnly={true} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color='primary'>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default DrawingsDialog;
