import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Snackbar,
  Input,
  InputAdornment,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Alert } from '@mui/material';
import { gql, useMutation } from '@apollo/client';
import CancelBidDialog from './CancelBidDialog';

const UPDATE_BID = gql`
  mutation installerUpdateBid(
    $projectInstallerBidInput: InstallerPortalProjectInstallerBidInput
  ) {
    installerUpdateBid(projectInstallerBidInput: $projectInstallerBidInput)
  }
`;

const useStyles = makeStyles()((theme) => ({
  open: {
    marginLeft: 'auto',
  },
  warning: {
    color: 'red',
  },
}));

const UpdateBidDialog = (props) => {
  const { classes } = useStyles();
  const { id, installationCost, projectNumber, installerBid } = props;
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [bid, setBid] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [updateBid, { data }] = useMutation(UPDATE_BID, {
    refetchQueries: ['getProjects', 'installerPortalProjectInstallerBidsCount'],
  });

  React.useEffect(() => {
    if (data && data.installerUpdateBid === 'OK') {
      setAlertOpen(true);
    }
  }, [data]);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setBid('');
    setDialogOpen(false);
  };

  const handleSubmit = () => {
    updateBid({
      variables: { projectInstallerBidInput: { id, bid: Number(bid) } },
    });
    setDialogOpen(false);
  };

  const handleBidChange = (event) => {
    if (event.target.value > installationCost) {
      setErrorMessage('The bid amount is too high');
    } else {
      setErrorMessage('');
    }

    setBid(event.target.value);
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  return (
    <React.Fragment>
      <Button
        variant='contained'
        color='grey'
        aria-label='update bid'
        className={classes.open}
        onClick={handleDialogOpen}
      >
        Update Bid
      </Button>
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Update Bid</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Your current bid: £{installerBid}
          </DialogContentText>
          <Input
            type='number'
            autoFocus
            className={classes.input}
            required
            fullWidth
            value={bid}
            onChange={handleBidChange}
            startAdornment={<InputAdornment position='start'>£</InputAdornment>}
          />
          {errorMessage && (
            <div className={classes.warning}>{errorMessage}</div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color='darkGrey'>
            Close
          </Button>
          <CancelBidDialog
            id={id}
            projectNumber={projectNumber}
            closeUpdateBidDialogCallback={handleDialogClose}
          />
          <Button
            onClick={handleSubmit}
            color='primary'
            disabled={bid <= 0 || bid > installationCost}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <Alert
          onClose={handleAlertClose}
          severity='success'
          elevation={6}
          variant='filled'
        >
          Bid of £{bid} has been placed on project P{projectNumber}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};

export default UpdateBidDialog;
