import React from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { Email as EmailIcon, Phone as PhoneIcon } from '@mui/icons-material';
import { makeStyles } from 'tss-react/mui';
import ProjectManagerChatDialog from './ProjectManagerChatDialog';

function ListItemLink(props) {
  return <ListItem button component='a' {...props} />;
}

const useStyles = makeStyles()((theme) => ({
  card: {
    minHeight: '250px',
  },
}));

const ProjectManagerDetails = (props) => {
  const { name, email, projectId } = props;
  const { classes } = useStyles();

  return (
    <Card className={classes.card}>
      <CardHeader
        title={name}
        subheader='Project Manager'
        action={<ProjectManagerChatDialog projectId={projectId} />}
      />
      <CardContent>
        <List component='nav' aria-label='project manager contact details'>
          <ListItemLink href={`mailto:${email}`}>
            <ListItemIcon>
              <EmailIcon />
            </ListItemIcon>
            <ListItemText primary={email} />
          </ListItemLink>
          <ListItemLink href='tel:01730233223'>
            <ListItemIcon>
              <PhoneIcon />
            </ListItemIcon>
            <ListItemText primary='01730 233223' />
          </ListItemLink>
        </List>
      </CardContent>
      <CardActions disableSpacing></CardActions>
    </Card>
  );
};

export default ProjectManagerDetails;
