import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { Grid, CircularProgress, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Pagination } from '@mui/material';
import ProjectSummary from '../components/ProjectSummary';
import ProjectSearch from '../components/ProjectSearch';

const displayedProjectCount = 12;

const PROJECT_COUNT = gql`
  {
    installerPortalProjectInstallerBidsCount
  }
`;

const GET_PROJECTS = gql`
    query getProjects($skip: Int!) {
      installerPortalProjectInstallerBids(orderBy: createdAt_DESC, limit: ${displayedProjectCount}, skip: $skip) {
        id
        customerApproved
        declined
        cancelled
        bid
        project {
          uuid
          projectNumber
          complete
          euAddressShort
          installationCost
        }
      }
    }
`;

const useStyles = makeStyles()({
  root: {
    flexGrow: 1,
  },
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const Projects = (props) => {
  const { skip } = props;
  const { loading, error, data } = useQuery(GET_PROJECTS, {
    fetchPolicy: 'network-only',
    variables: { skip },
  });

  if (loading) return <CircularProgress color='inherit' />;
  if (error) return <p>Error :(</p>;

  return (
    <React.Fragment>
      {data.installerPortalProjectInstallerBids.map(
        ({ project, customerApproved, declined, cancelled, bid, id }) => (
          <Grid item xs={12} sm={6} md={3} key={project.projectNumber}>
            <ProjectSummary
              id={project.uuid}
              projectNumber={project.projectNumber}
              euAddressShort={project.euAddressShort}
              complete={project.complete}
              approved={customerApproved}
              declined={declined}
              cancelled={cancelled}
              installationCost={project.installationCost}
              installerBid={bid}
              bidId={id}
            />
          </Grid>
        )
      )}
    </React.Fragment>
  );
};

const ProjectList = () => {
  const { classes } = useStyles();
  const [skip, setSkip] = React.useState(0);
  const { loading, error, data } = useQuery(PROJECT_COUNT);

  if (loading) return <CircularProgress color='inherit' />;
  if (error) return <p>Project List Error</p>;
  if (data.installerPortalProjectInstallerBidsCount < 1)
    return (
      <Typography variant='h4'>
        Nothing to see here! Check back when your company has some projects.
      </Typography>
    );

  const pages = Math.ceil(
    data.installerPortalProjectInstallerBidsCount / displayedProjectCount
  );

  const handlePageChange = (_, page) => {
    setSkip((page - 1) * displayedProjectCount);
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} className={classes.center}>
          <ProjectSearch />
        </Grid>
        <Projects skip={skip} />
        <Grid item xs={12} className={classes.center}>
          <Pagination count={pages} onChange={handlePageChange} />
        </Grid>
      </Grid>
    </div>
  );
};

export default ProjectList;
