import React from 'react';
import { Card, CardHeader, CardActions, Chip, Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Link } from 'react-router-dom';
import UpdateBidDialog from './UpdateBidDialog';

const useStyles = makeStyles()({
  buttons: {
    marginLeft: 'auto',
  },
  chip: {
    color: 'white',
  },
});

const ProjectSummary = (props) => {
  const {
    id,
    projectNumber,
    euAddressShort,
    complete,
    approved,
    declined,
    cancelled,
    installationCost,
    installerBid,
    bidId,
  } = props;
  const { classes } = useStyles();

  const getBadge = () => {
    if (approved && !cancelled) return 'Approved';
    if (declined) return 'Declined';
    if (cancelled) return 'Cancelled';
    if (!approved && !declined && !complete && !cancelled)
      return 'Awaiting Approval';
    if (approved && complete) return 'Completed';
  };

  return (
    <Card style={{ minHeight: '150px' }}>
      <CardHeader
        title={`P${projectNumber}`}
        subheader={euAddressShort}
        action={
          <Chip
            size='small'
            label={getBadge()}
            color='primaryRed'
            className={classes.chip}
          />
        }
      />
      {id && approved && !cancelled && (
        <CardActions>
          <Button
            component={Link}
            to={`/projects/${id}`}
            variant='contained'
            color='grey'
            aria-label='open'
            className={classes.buttons}
          >
            Open
          </Button>
        </CardActions>
      )}
      {id && !approved && !declined && !complete && !cancelled && (
        <CardActions>
          <UpdateBidDialog
            id={bidId}
            installationCost={installationCost}
            projectNumber={projectNumber}
            installerBid={installerBid}
          />
        </CardActions>
      )}
    </Card>
  );
};

export default ProjectSummary;
